import Axios from "axios"

export default {
  state: () => ({
    customersUrl: process.env.VUE_APP_URL+'/clientes',
    loading: false,
    customers: [],
    reports: [],
    selectedCustomer: 0
  }),
  mutations: {
    setCustomers(state,customers){
        state.customers = customers
        state.loading = false
    },
    setSelectedCustomer(state,index){
        state.selectedCustomer = index
    },
    setCustomersLoading(state,val){
        state.loading = val
    },
    setCustomerReports(state,val){
        state.reports = val
    }
  },
  actions: {
    async getAllCustomers(context){
        context.commit('setCustomersLoading',true)
        let records = [];
        let page = 0;
        let totalPages = 0;
        try {
            do {
                page = ++page;
                let { data: response }  = await Axios.get( context.state.customersUrl+'?page='+page,{headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken')}});
                totalPages = response.meta.last_page;
                records = records.concat(response.data);
            } while (page < totalPages)
            context.commit('setCustomers',records)
            context.commit('setOfflineMode',false)
        } catch (error) {
            if(error.code == 'ERR_NETWORK'){
                context.commit('setOfflineMode',true)
                context.commit('setCustomersLoading',false)
            }else{
                console.log(error)
                context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:CU1', 'Ocurrió un error')
            }
        }
    },
    async getAllReportsByCustomer(context,id){
      context.commit('setCustomersLoading',true)
      let records = [];
      let page = 0;
      let totalPages = 0;
      try {
          do {
              page = ++page;
              let { data: response }  = await Axios.get( context.state.customersUrl+'/'+id+'/reportes',{headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken')}});
              totalPages = response.meta.last_page;
              records = records.concat(response.data);
          } while (page < totalPages)
          context.commit('setCustomerReports',records)
          context.commit('setCustomersLoading',false)
          context.commit('setOfflineMode',false)
          if(records.length == 0){
            context.rootState.framework7.app.dialog.alert('No hay reportes en esta empresa', 'Sin reportes')
          }
      } catch (error) {
          if(error.code == 'ERR_NETWORK'){
              context.commit('setOfflineMode',true)
              context.commit('setCustomersLoading',false)
          }else{
              context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:CU1', 'Ocurrió un error')
              console.log(error)
          }
      }
    }
  },
  getters: {}
}
import Axios from "axios"

export default {
  state: () => ({
    token: '',
    loginUrl: process.env.VUE_APP_URL+'/login',
    meUrl: process.env.VUE_APP_URL+'/user'
  }),
  mutations: {
    setSessionToken(state,token){
        localStorage.setItem('sessionToken',token)
        state.token = token
    }
  },
  actions: {
    checkSession(context){
        var token = localStorage.getItem('sessionToken')
        var user = JSON.parse(localStorage.getItem('userInfo'))
        if(token != undefined && token != '' && user != undefined && user != {}){
            context.commit('setSessionToken',token)
            context.commit('setUser',user)
            context.dispatch('callMeApi')
        }else{
            context.commit('setLayout','login')
        }
    },
    callMeApi(context){

        const config = {
            headers: { Authorization: 'Bearer '+context.state.token }
        };

        Axios.get( 
          context.state.meUrl,
          config
        ).then(function (response) {
            context.commit('setUser',response.data)
            context.commit('setLayout','main')
        }).catch(function (error) {
            if(error.code == 'ERR_BAD_REQUEST'){
                context.commit('setSessionToken','')
                context.commit('setLayout','login')
                context.commit('setOfflineMode',false)
            }else if(error.code == 'ERR_NETWORK'){
                console.log('offlineMode: True')
                context.commit('setOfflineMode',true)
                context.commit('setLayout','main')
            }else{
                context.commit('setOfflineMode',false)
                context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:MA1', 'Ocurrió un error')
            }
        });
    },
    login(context,data){
        context.commit("loadingText","Iniciando sesión...")
        context.commit("loading",true)
        Axios.post( 
          context.state.loginUrl,
          data
        ).then(function (response) {
            context.commit("loading",false)
            context.commit('setSessionToken',response.data.token)
            context.dispatch('callMeApi')
        }).catch(function (error) {
            context.commit("loading",false)
            if(error.code == 'ERR_BAD_REQUEST'){
                context.rootState.framework7.app.dialog.alert('', 'Usuario o contraseña incorrectos')
            }else if(error.code == 'ERR_NETWORK'){
                context.rootState.framework7.app.dialog.alert('Revisa tu conexión a internet', 'Sin conexión')
            }else{
                context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:LG1', 'Ocurrió un error')
            }
            console.log(error)
        });
    },
    logout(context){
        context.commit('setSessionToken','')
        context.commit('resetReports')
        context.commit('setUser',{})
        context.commit('setLayout','login')
    }
  },
  getters: {}
}
const data = {
  "data": {
    "Prueba_Hidrostática": {
      "type": "date"
    },
    "Presión_actual": {
      "type": "input"
    },
    "Estado_del_Cilindro": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Arnés": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Mascarilla": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Daños_Físicos": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Señalamiento": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Acción_Correctiva_Fecha_Compromiso": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion",
    "No_de_serie",
    "Marca",
    "Marca",
    "Fecha_de_fabricación"
  ]
}

module.exports = data
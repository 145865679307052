<template>
  <div class="report" @click="saveData()">
    <div class="main">
        <div class="page">
            <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                    <div class="left">
                    <a class="link back" @click="goBack()">
                        <i class="icon icon-back"></i>
                        <span class="if-not-md">Atrás</span>
                    </a>
                    </div>
                    <div class="title"></div>
                </div>
            </div>
            <div :class="isDarkMode()">
                <div v-if="loaded">
                    <h1 class="margin-left">{{asset.id_cabil}}</h1>
                    <div v-for="(val,i) in asset" :key="i+'asset'" class="list no-hairlines-md">
                        <ul v-if="form.assetFields.includes(i)">
                            <li class="item-content item-input">
                                <div class="item-inner">
                                    <p>{{processName(i)}}:<br> <b>{{asset[i]}}</b></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-for="(val,i) in report" :key="i+'report'" class="list no-hairlines-md">
                        <ul v-if="form.data[i] != undefined">
                            <li v-if="isInput(form.data[i].type)" class="item-content item-input">
                                <div class="item-inner">
                                    <p>{{processName(i)}}:</p>
                                    <div class="item-input-wrap">
                                        <input v-if="form.data[i].type == 'date'" type="date" :value="getDate(i)" @change="dateChange(i,$event)">
                                        <input v-if="form.data[i].type == 'input'" type="text" :placeholder="'('+processName(i)+')'" v-model="report[i]"/>
                                        <textarea v-if="form.data[i].type == 'textarea'" class="resizable" :placeholder="'('+processName(i)+')'" v-model="report[i]"></textarea>
                                        <select v-if="form.data[i].type == 'select'" v-model="report[i]">
                                            <option value="" disabled selected="selected">Selecciona una opción</option>
                                            <option v-for="(option,j) in form.data[i].options" :key="j" :value="j">{{option}}</option>
                                        </select>
                                    </div> 
                                </div>
                            </li>
                            <div v-if="form.data[i].type == 'radio' && !isInput(form.data[i].type)" class="list no-hairlines-md">
                                <p class="label-radio">{{processName(i)}}:</p>
                                <ul class="radio-options">
                                    <li v-for="(option,j) in form.data[i].options" :key="j">
                                        <label class="item-radio item-radio-icon-start item-content">
                                            <input type="radio" :value="j" v-model="report[i]"/>
                                            <i class="icon icon-radio"></i>
                                            <div class="item-inner">
                                                <div class="item-title">{{option}}</div>
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="form.data[i].type == 'json' && !isInput(form.data[i].type)" class="list no-hairlines-md">
                                <p class="label-radio">{{processName(i)}}:</p>
                                <div v-for="(field,j) in form.data[i].keys" :key="j">
                                    <div v-if="field.type == 'radio'" class="inner-json-field">
                                        <p class="label-radio">{{processName(j)}}:
                                            <span v-if="field.label != undefined" class="meta-text"> *{{field.label}}</span>
                                        </p>
                                        
                                        <ul class="radio-options">
                                            <li v-for="(option,k) in field.options" :key="k">
                                                <label class="item-radio item-radio-icon-start item-content">
                                                    <input type="radio" :value="k" v-model="report[i][0][j]"/>
                                                    <i class="icon icon-radio"></i>
                                                    <div class="item-inner">
                                                        <div class="item-title">{{option}}</div>
                                                    </div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-if="field.type == 'input'" class="inner-json-field item-input">
                                        <p class="label-radio">{{processName(j)}}:
                                            <span v-if="field.label != undefined" class="meta-text"> *{{field.label}}</span>
                                        </p>
                                        <div class="item-input-wrap label-radio">
                                            <input type="text" class="input-margin" :placeholder="'('+processName(j)+')'" v-model="report[i][0][j]"/><!--Antes no guardaba porque empezo a venir con el array 0 y adentro el objeto-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const Forms = require('../assets/forms/forms')
const ReportUtils = require('../assets/lib/reportUtils')
import PouchDB from 'pouchdb'

export default {
    name: 'Report',
    data () {
        return {
            report: {},
            asset: {},
            form: {},
            reportReady: false,
            assetReady: false,
            db:{},
            enableUpdate:true
        }
    },
    props:{
        id: String,
        reportId: String
    },
    mounted(){
        this.getReport()
    },
    methods: {
        getReport: function () {
            this.db = PouchDB('report_'+this.reportId)
            this.db.get('asset_'+this.id).then((asset)=>{
                this.asset = asset
                this.assetReady = true
            })
            this.db.get('report_'+this.id).then((report)=>{
                this.report = report
                this.reportReady = true
            })
        },
        goBack: function () {
            this.saveData()
            if(this.lastRoute == "Qr"){
                window.location.href="#/"
            }else{
                window.history.back()
            }
        },
        processName: function (name) {
            name = name.replaceAll('_',' ')
            name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            return name.replace(/-/g, '');
        },
        saveData: function () {
            let progress = ReportUtils.getReportProgress(this.report)
            this.report.cabil_app_progress = progress
            if(this.enableUpdate){
                this.db.put(this.report).then((response)=>{
                    this.report._rev = response.rev
                }).then(()=>{
                    this.asset.cabil_app_progress = progress
                    this.db.put(this.asset).then((response)=>{
                        this.asset._rev = response.rev
                    })
                })
                this.updateTimeout()
            }
            
        },
        getDate: function (i) {
            let date = ''
            try {
                date = this.report[i].split('T')[0]
            } catch {
                date = ''
            } 
            return date
        },
        dateChange: function (key,val) {
            const time = val.srcElement.value+'T00:00:00.000000Z'
            this.report[key] = time
            this.saveData()
        },
        isInput: function (type) {
            if(type == 'textarea' || type == 'date' || type == 'select' || type=='input'){
                return true
            }
            return false
        },
        isDarkMode: function () {
            if(!this.darkMode){
                return 'page-content gray-background'
            }
            return 'page-content'
        },
        updateTimeout: function () {
            this.enableUpdate = false
            setTimeout(()=>{
                this.enableUpdate = true
            },500)
        }
    },
    computed: {
        reports: function () {
            return this.$store.state.reports.reports 
        },
        lastRoute: function () {
            return this.$store.state.lastRoute
        },
        darkMode: function () {
            return this.$store.state.darkMode
        },
        loaded: function () {
            return (this.reportReady && this.assetReady)
        }
    },
    watch: {
        asset: function (val) {
            this.form = Forms[val.Tipo]
        }
    }

}
</script>

<style lang="css" scoped>
    .resizable{
        overflow: hidden;
    }
    .radio-options{
        padding-left:0;
    }
    .label-radio{
        margin-left:16px;
        padding-top: 16px;
    }
    .gray-background{
        background:#eeeeee;
    }
    .inner-json-field{
        margin-left:16px;
    }
    .meta-text{
        color: gray;
        font-size: 14px;
    }
</style>
<template>
  <div class="mantenimientos">
    <div class="main">
          <div v-if="!isCameraOpen" class="page">
            <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                    <div class="left">
                    <a class="link back" @click="goBack()">
                        <i class="icon icon-back"></i>
                        <span class="if-not-md">Atrás</span>
                    </a>
                    </div>
                    <div class="title"></div>
                </div>
            </div>
            <place-holder v-if="!loadingReady"/>
            <div v-if="loadingReady" class="page-content">
                <div class="block">
                    <h3>{{mantenimiento.Asunto}}</h3>
                </div>
                <hr>
                <div class="list list-strong-ios list-dividers-ios inset-ios">
                  <ul>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Asunto</div>
                        <div class="item-input-wrap">
                          <input type="text" placeholder="Asunto" v-model="mantenimiento.Asunto">
                          <span class="input-clear-button"></span>
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Fecha de realización</div>
                        <div class="item-input-wrap">
                          <input type="date" :value="getDate(mantenimiento.Fecha_de_realización)" @change="dateChange('Fecha_de_realización',$event)">
                        </div>
                      </div>
                    </li>
                    <li class="block">
                      <button class="button button-fill color-blue" @click="openEditor('Detalle')">Contenido</button>
                    </li>
                    <li class="block">
                      <button class="button button-fill color-teal" @click="openEditor('Observaciones')">Observaciones</button>
                    </li>
                    <li class="block padding-bottom">
                      <button class="button color-red button-fill" @click="toggleCamera()">Galería de fotos</button>
                    </li>
                  </ul>
                </div>
                <button class="button button-fill color-blue" @click="save()">Guardar</button>
            </div>
          </div>
          <div v-if="isCameraOpen" class="page">
            <div class="page-content">
              <video v-show="!isPhotoTaken" ref="camera" class="camera-video margin-bottom" autoplay></video>
              <div class="block">
                <div class="row">
                  <div class="col-50 margin-top" v-for="(image,i) in photoGallery" :key="i">
                    <div class="photo-container">
                      <img :src="image" class="photo">
                      <div class="photo-overlay"><i class="icon f7-icons color-red" @click="removePhoto(i)">trash</i></div>
                    </div>
                  </div>
                </div>
              </div>
              <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
            </div>
            <div class="fab fab-right-bottom">
              <a>
                <i class="icon f7-icons" @click="takePhoto()">camera</i>
              </a>
            </div>
            <div class="fab fab-center-bottom">
              <camera-selector/>
            </div>
            <div class="fab fab-left-bottom">
              <a>
                <i class="icon icon-back" @click="toggleCamera()"></i>
              </a>
            </div>
          </div>
    </div>
    <div id="popup-editor" class="popup page">
      <div class="block page-content">
        <div class="row">
          <div class="col-50">
            <h3>Editor de {{editorTitle}}</h3>
          </div>
          <div class="col-50 text-align-right">
            <p><a class="link popup-close" href="#"><i class="f7-icons">chevron_down</i> Cerrar</a></p>
          </div>
        </div>
        <div id="text-editor" class="text-editor">
          <div class="text-editor-content" contenteditable></div>
        </div>
        <div class="block-title">Lsta de activos</div>
        <button class="button button-fill color-blue margin-bottom" @click="showActivos = !showActivos">{{(showActivos)?'Ocultar':'Mostrar'}} activos</button>
        <button v-if="selectedAssets.length > 0 && showActivos" class="button button-fill color-teal" @click="addToText()">Agregar al texto</button>
        <div v-if="showActivos" class="list margin-botton">
          <ul>
            <li v-for="(assets,i) in assetCatalog" :key="i">
              <a class="item-link smart-select smart-select-init" data-open-in="popover">
                <select v-model="selectedAssets" multiple>
                  <option v-for="(asset,j) in assets" :key="j" :value="createNameOfList(asset)">{{createNameOfList(asset)}}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{i}}</div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import PouchDB from 'pouchdb'
import PlaceHolder from '@/components/reports/assetsPlaceholder.vue'
import CameraSelector from '../../components/camera/cameraSelector.vue'
export default {
  name:'MantenimientosEdit',
  props: {
    reportId: String,
    id: String
  },
  data () {
    return {
      loadingReady: false,
      mantenimiento: [],
      textEditor: {},
      editedParam: '',
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      assetCatalog: {},
      photoGallery: [],
      selectedAssets:[],
      showActivos: false
    }
  },
  methods: {
    getMantenimiento: function () {
      let db = new PouchDB('report_'+this.reportId)
      db.get('mantenimiento_'+this.id).then((mantenimiento)=>{
          this.mantenimiento = mantenimiento
          this.photoGallery = JSON.parse(this.mantenimiento.galeria)
          this.loadingReady = true
      })
    },
    goBack: function () {
        this.mantenimiento.galeria = JSON.stringify(this.photoGallery)
        let db = new PouchDB('report_'+this.reportId)
        db.put(this.mantenimiento).then((response)=>{
            this.mantenimiento._rev = response.rev
        })
        window.history.back()
    },
    getDate: function (date) {
        try {
            date = date.split('T')[0]
        } catch {
            date = ''
        } 
        return date
    },
    dateChange: function (key,val) {
        const time = val.srcElement.value+'T00:00:00.000000Z'
        this.mantenimiento[key] = time
    },
    startTextEditor: function () {
      const vue = this
      this.textEditor = this.f7.textEditor.create({
        el: '#text-editor',
        buttons: [
            ['bold'],
            ['unorderedList']
          ],
        on: {
            change: function (value) {
              vue.updateParamValue(value)
            }
          }
      });
    },
    save: function () {
      this.$store.commit("loadingText","Guardando...")
      this.$store.commit("loading",true)
      this.mantenimiento.galeria = JSON.stringify(this.photoGallery)
      let db = new PouchDB('report_'+this.reportId)
      db.put(this.mantenimiento).then((response)=>{
          this.mantenimiento._rev = response.rev
          this.$store.commit("loading",false)
          this.f7.dialog.alert('Mantenimiento guardado en el dispositivo', 'Guardado')
      })
    },
    toggleCamera() {
      if(this.isCameraOpen) {
        this.stopCameraStream();
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;
      const constraints = {audio:false, video: { deviceId: { exact: this.selectedCameraId } }};
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch(error => {
          this.isLoading = false;
					alert("May the phone didn't support or there is some errors.");
          console.log(error)
				});
    },
    takePhoto() {
      const canvas = this.$refs.canvas
      const context = canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      var dataURI = document.getElementById("photoTaken").toDataURL("image/jpeg")
      this.photoGallery.push(dataURI)
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
			tracks.forEach(track => {
				track.stop();
			});
    },
    removePhoto(i){
      const vue = this
      this.f7.dialog.confirm('¿Deseas eliminar esta foto del reporte?','<img src="'+this.photoGallery[i]+'" class="photo"/>', function () {
          vue.photoGallery.splice(i, 1);
      }, function () {})
    },
    openEditor(value){
      this.editedParam = value
      this.textEditor.setValue(this.mantenimiento[value]) 
      this.f7.popup.open('#popup-editor', true)
    },
    updateParamValue: function (value){
      this.mantenimiento[this.editedParam] = value.value
    },
    getAssets: function () {
        let db = new PouchDB('report_'+this.reportId)
        db.allDocs({startkey: "asset_", endkey: "asset_\ufff0", include_docs: true},(err, assets) => {
                if (err) { return console.log(err); }
                this.catalogAsset(assets.rows)
              })
    },
    catalogAsset: function (assets) {
        let catalog = {}
        for(let i in assets){
            if(catalog[assets[i].doc['Tipo']] == undefined){
                catalog[assets[i].doc['Tipo']] = []
            }
            catalog[assets[i].doc['Tipo']].push(assets[i].doc)
        }
        this.assetCatalog = catalog
    },
    createNameOfList: function (asset) {
      let ubicacion = (asset.Ubicación != undefined)?' - '+asset.Ubicación:''
      return asset.id_cabil+ubicacion
    },
    addToText: function () {
      let selected = this.selectedAssets
      this.selectedAssets = []
      let text = '<ul>'
      for(let i in selected){
        text = text+'<li>'+selected[i]+'</li>'
      }
      text = text+'</ul>'
      text = this.textEditor.getValue()+text
      this.textEditor.setValue(text)
    }
  },
  computed:{
    f7: function () {
      return this.$store.state.framework7.app
    },
    editorTitle: function () {
      let obj = {
        'Detalle': 'Contenido',
        'Observaciones': 'Observaciones'
      }
      return obj[this.editedParam]
    },
    selectedCameraId: function () {
      return this.$store.state.camera.selectedId
    }
  },
  watch: {
    selectedCameraId: function () {
      this.createCameraElement()
    }
  },
  mounted () {
    this.getMantenimiento()
    this.getAssets()
    this.startTextEditor()
  },
  components:{
    PlaceHolder,
    CameraSelector
  }
}
</script>

<style>
.camera-video{
  width:100%;
}
.photo{
  display: block;
  width: 100%;
}
.photo-container {
  position: relative;
  width: 100%;
}
.photo-overlay {
  position: absolute; 
  top: 0; 
  color: #f1f1f1; 
  width: 100%;
  opacity:1;
  color: white;
  font-size: 20px;
  text-align: right;
}
.text-editor{
  height:400px;
}
</style>


<template>
  <div class="main">
    <router-view/>
  </div>
</template>

<script>
export default {
    name: "Main"

}
</script>

<style>

</style>
<template>
  <div class="customers">
        <placeholder v-if="loading"/>
        <div v-if="!loading">
            <div v-if="!offline && reports.length == 0">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <div class="title">Descarga de reportes</div>
                    </div>
                </div>
                <div class="list links-list">
                    <ul>
                    <li v-for="(customer,i) in customers" :key="i" @click="selectCustomer(customer.id,customer.Nombre_interno,i)">
                        <a href="#">{{customer.Nombre_interno}}</a>
                    </li>
                    </ul>
                </div>
            </div>
            <div v-if="!offline && reports.length > 0">
                <div class="block"><i class="f7-icons" @click="goBack()">arrow_left</i></div>
                
                <div class="block"><h3>Reportes de {{customerName}}</h3></div>
                <div class="list">
                    <ul>
                    <li v-for="(report,i) in reports" :key="i" @click="selectReport(i)">
                        <div class="item-content">
                        <div class="item-media"><i class="f7-icons">cloud_download</i></div>
                        <div class="item-inner">
                            <div class="item-title">{{report.Nombre}}</div>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>
            </div>
            <div v-if="offline" class="card">
                <div class="card-header">
                    <h2>Modo Offline</h2>
                </div>
                <div class="card-content card-content-padding">
                    <p>No se puede mostrar el panel de descarga de reportes sin una conexión a internet.</p>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import Placeholder from './customersPlaceholder.vue'
export default {
    name: 'Customers',
    data () {
        return {
            section: 'customerlist',
            customerName: ''
        }
    },
    mounted () {
        this.$store.commit('setCustomerReports',[])
        this.$store.dispatch('getAllCustomers')
    },
    components: {
        Placeholder
    },
    computed: {
        loading: function () {
            return this.$store.state.customers.loading
        },
        customers: function () {
            return this.$store.state.customers.customers
        },
        offline: function () {
            return this.$store.state.offlineMode
        },
        reports: function () {
            return this.$store.state.customers.reports
        },
        f7: function () {
            return this.$store.state.framework7.app
        },
        reportProgress: function () {
            return this.$store.state.reports.progress
        }
    },
    methods: {
        selectCustomer: function (id,name,index) {
            this.customerName = name
            this.$store.commit('setSelectedCustomer',index)
            this.$store.dispatch('getAllReportsByCustomer',id)
        },
        goBack: function () {
            this.$store.commit('setCustomerReports',[])
            this.$store.dispatch('getAllCustomers')
        },
        selectReport: function (i){
            const vue = this
            this.f7.dialog.confirm('¿Seguro que deseas descargar la información de este reporte a tu teléfono?','Confirmar descarga', function () {
                vue.downloadReportData(i)
            }, function () {})
        },
        downloadReportData: function (i) {
            var report = this.reports[i]
            this.$store.dispatch('getReportsWithAssets',report.id)
        }
        
    }
}
</script>

<style>

</style>
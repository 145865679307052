<template>
  <div class="login view">
    <div class="page">
        <div class="page-content login-screen-content block">
            <p class="text-align-center"><img class="logo-img" src="@/assets/img/casquito.png"/></p>
            <div class="login-screen-title">CABIL APP</div>
            <div class="list">
                <ul>
                <li class="item-content item-input">
                    <div class="item-inner">
                    <div class="item-title item-label">Usuario</div>
                    <div class="item-input-wrap">
                        <input type="text" name="username" placeholder="Usuario" v-model="email" />
                    </div>
                    </div>
                </li>
                <li class="item-content item-input">
                    <div class="item-inner">
                    <div class="item-title item-label">Password</div>
                    <div class="item-input-wrap">
                        <input type="password" name="password" placeholder="Password" v-model="password"/>
                    </div>
                    </div>
                </li>
                </ul>
            </div>
            <div class="list">
                <ul>
                <li><button @click="validate()" class="button button-large button-fill">Entrar</button></li>
                </ul>
            </div>
            <p class="margin-top text-align-center">&copy; {{year}} AppCabil - ver. {{version}}</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'login',
    data () {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        validate: function () {
            if(this.email == ''){
                const text = ''
                const title = 'El usuario es obligatorio'
                this.f7.dialog.alert(text, title)
                return false
            }
            if(this.password == ''){
                const text = ''
                const title = 'La contraseña está vacía'
                this.f7.dialog.alert(text, title)
                return false
            }
            this.login()
        },
        login: function () {
            const data = {
                email: this.email,
                password: this.password
            }
            this.$store.dispatch('login',data)
        }
    },
    computed: {
        f7: function () {
            return this.$store.state.framework7.app
        },
        version: function () {
        return this.$store.state.version
        },
        year: function () {
            let date = new Date()
            return date.getFullYear()
        },
    }
}
</script>

<style scoped>
.logo-img{
    width:60%;
    opacity:0.9;
}
</style>

<template>
  <div class="reportProgress">
    <div :class="'gauge gauge-progreso-'+item"></div>
  </div>
</template>

<script>
import PouchDB from "pouchdb"

export default {
    name: 'ReportGauge',
    data () {
        return {
            percentage: 0
        }
    },
    props: {
        item: String
    },
    mounted() {
        this.getProgress()
    },
    methods: {
        getProgress: function () {
            const db = new PouchDB(this.item)
            db.allDocs({startkey: "report_", endkey: "report_\ufff0", include_docs: true}).then((reports) => {
                let sum = 0
                let total = 0
                for(let i in reports.rows){
                    sum = sum + reports.rows[i].doc.cabil_app_progress
                    total = total+1
                }
                this.percentage = ((sum*100)/(total*100)).toFixed(2)
                if(isNaN(this.percentage)){
                    this.percentage = 0
                }
                var color = '#ff3b30'
                if(this.percentage > 40){
                    color = '#ff9500'
                }
                if(this.percentage > 80){
                    color = '#2196f3'
                }
                if(this.percentage > 99){
                    color = '#4cd964'
                }
                
                this.f7.gauge.create({
                    el: '.gauge-progreso-'+this.item,
                    type: 'circle',
                    value: this.percentage/100,
                    size: 250,
                    borderColor: color,
                    borderWidth: 10,
                    valueText: this.percentage+'%',
                    valueFontSize: 41,
                    valueTextColor: color,
                    labelText: '',
                });
            })
            
        }
    },
    computed: {
        f7: function () {
            return this.$store.state.framework7.app
        }
    },
    watch: {
        item: function () {
            this.getProgress()
        }
    }

}
</script>

<style>

</style>
const data = {
  "data": {
    "Poleando": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Libre_de_obstrucción": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Daños_físicos": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Acción_Correctiva_Fecha_Compromiso_Causa": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "input"
        },
        "Causa_No_Polea---": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion"
  ]
}

module.exports = data
import Vue from 'vue'
import Vuex from 'vuex'
import f7 from './framework7.js'
import Session from './session.js'
import Customers from './customers.js'
import Reports from './reports.js'
import Camera from './camera.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: process.env.VUE_APP_VERSION,
    layout: "",
    loading: false,
    loadingText: "",
    user: {},
    offlineMode: false,
    darkMode: false, //default value
    colorTheme: 'color-theme-red',
    lastRoute: '/'
  },
  mutations: {
    setLayout(state,layout){
      state.layout = layout
    },
    loading(state,val){
      state.loading = val
    },
    loadingText(state,text){
      state.loadingText = text
    },
    setUser(state,user){
      localStorage.setItem('userInfo',JSON.stringify(user))
      state.user = user
    },
    setOfflineMode(state,val){
      state.offlineMode = val
    },
    setLastRoute(state,route){
      state.lastRoute = route
    },
    getSavedUser(state){
      state.user = JSON.parse(localStorage.getItem('userInfo'))
    },
    setDarkMode(state,val=undefined){
      if(val == undefined){
        val = localStorage.getItem('darkMode')
        if(val == null){
          val = state.darkMode
        }else{
          val = (val == "true")?true:false
        }
      }
      localStorage.setItem('darkMode',val)
      if(val){
        document.body.className = state.colorTheme+' framework7-root dark'
      }else{
        document.body.className = state.colorTheme+' framework7-root'
      }
      state.darkMode = val
    }
  },
  actions: {
    startApp(context){
      context.dispatch('initFramework7')
      context.commit('setDarkMode')
      console.log('CabilApp Version: '+context.state.version)
      context.dispatch('initApp')
    },
    initApp(context){
      context.commit('getSavedUser')
      context.commit("getSavedReports")
      context.dispatch("checkSession")
      context.commit("initCamera")
    }
  },
  modules: {
    framework7: f7,
    session: Session,
    customers: Customers,
    reports: Reports,
    camera: Camera
  }
})

<template>
  <div class="reportProgress">
    <p v-if="hasReports" class="text-percentage">Progreso: {{percentage}}%</p>
    <p v-if="!hasReports" class="text-percentage">No hay reportes asignados</p>
    <p><span data-progress="0" :class="'progressbar color-'+color" :id="'id-progress-report-'+count"></span></p>
  </div>
</template>

<script>
export default {
    name: 'ReportProgress',
    data () {
        return {
            total: 0,
            completed: 0,
            percentage: 0,
            color: 'red',
            hasReports: true
        }
    },
    props: {
        report: Number,
        count: Number
    },
    mounted() {
        this.getProgress()
    },
    methods: {
        getProgress: function () {
            this.percentage = this.report
            if(isNaN(this.percentage)){
                this.percentage = 0
                this.hasReports = false
            }
            var color = 'red'
            if(this.percentage > 40){
                color = 'yellow'
            }
            if(this.percentage > 80){
                color = 'blue'
            }
            if(this.percentage > 99){
                color = 'green'
            }
            this.color = color
            this.f7.progressbar.set('#id-progress-report-'+this.count, this.percentage);
        }
    },
    computed: {
        f7: function () {
            return this.$store.state.framework7.app
        }
    },
    watch: {
        reports: function () {
            this.getProgress()
        }
    }

}
</script>

<style>
.text-percentage{
    margin:0;
}
</style>
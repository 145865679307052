const data = {
  "data": {
    "Manómetro": {
      "type": "select",
      "options": {
        "Cargado": "Cargado",
        "Descargado": "Descargado",
        "Sobrecargado": "Sobrecargado",
        "N/A": "N/A"
      }
    },
    "Presencia_del_Extintor": {
      "type": "radio",
      "options": {
        "Presente": "Presente",
        "Ausente": "Ausente"
      }
    },
    "Libre_de_obstáculos": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Altura_Máxima_15_mts": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Manguera": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal"
      }
    },
    "Boquilla": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal"
      }
    },
    "Márchamo": {
      "type": "radio",
      "options": {
        "Presente": "Presente",
        "Ausente": "Ausente"
      }
    },
    "Precinto_de_Seguridad": {
      "type": "radio",
      "options": {
        "Presente": "Presente",
        "Roto": "Roto",
        "N/A": "N/A"
      }
    },
    "Señalización": {
      "type": "radio",
      "options": {
        "Presente": "Presente",
        "Ausente": "Ausente"
      }
    },
    "Prueba_Hidrostática": {
      "type": "date"
    },
    "Próxima_prueba_hidrostática": {
      "type": "date"
    },
    "Fecha_última_recarga": {
      "type": "date"
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Condiciones_del_Cilindro": {
      "type": "json",
      "keys": {
        "Golpeado-": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Corroido--": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Protegido_del_Ambiente---": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No",
            "N/A": "N/A"
          }
        },
        "Condiciones----": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No",
            "N/A": "N/A"
          }
        }
      }
    },
    "Etiqueta": {
      "type": "json",
      "keys": {
        "Presente-": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Datos_Proveedor--": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Instrucciones_Uso---": {
          "type": "radio",
          "options": {
            "Presente": "Presente",
            "Ausente": "Ausente"
          }
        }
      }
    },
    "Holograma_NOM_154_SCFI_2005": {
      "type": "json",
      "keys": {
        "Dictamen-": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No",
            "N/A": "N/A"
          }
        },
        "Prueba_PH--": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicación",
    "Tipo_Agente_extintor",
    "Capacidad"
  ]
}

module.exports = data
import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Assets from '../views/Assets.vue'
import Report from '../views/Report.vue'
import QR from '../views/Qr.vue'
import MantenimientosList from '../views/mantenimientos/MantenimientosList.vue'
import MantenimientosEdit from '../views/mantenimientos/MantenimientosEdit.vue'
import Refresh from '../components/app/refresh.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/assets/:id',
    name: 'Assets',
    component: Assets,
    props: true
  },
  {
    path: '/report/:reportId/:id',
    name: 'Report',
    component: Report,
    props: true
  },
  {
    path: '/qr/:id',
    name: 'Qr',
    component: QR,
    props: true
  },
  {
    path: '/mantenimientos/:reportId',
    name: 'MantenimientosList',
    component: MantenimientosList,
    props: true
  },
  {
    path: '/mantenimientos/:reportId/:id',
    name: 'MantenimientosEdit',
    component: MantenimientosEdit,
    props: true
  },
  {
    path: '/refresh',
    name: 'Refresh',
    component: Refresh
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('setLastRoute',from.name)
  next()
})
export default router

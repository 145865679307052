const data = {
  "data": {
    "Descarga_de_agua_correcta": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Estado_de_Pintura": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Manija_de_descarga": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Estado_de_Válvula": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Daños_físicos": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Señalamiento": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Acción_Correctiva_Fecha_Compromiso": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion"
  ]
}

module.exports = data
<template>
  <div class="assets">
    <div class="main">
        <div class="page">
            <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                    <div class="left">
                    <a class="link back" @click="goBack()">
                        <i class="icon icon-back"></i>
                        <span class="if-not-md">Atrás</span>
                    </a>
                    </div>
                    <div class="title"></div>
                </div>
            </div>
            <place-holder v-if="!reportReady"/>
            <div v-if="reportReady" class="page-content">
                <div class="block">
                    <div>
                        <h3>Reporte: {{report.report.Nombre}}</h3>
                        <h3>Empresa: {{report.customer.Nombre_interno}}</h3>
                        <h3>Activos: {{report.report.cabil_app_assets_num}}</h3>
                    </div>
                    <hr>
                    <button class="button button-small button-fill color-blue" @click="goToMantenimientos()">mantenimientos</button>
                </div>
                    <div class="list list-strong list-outline-ios list-dividers-ios inset-md accordion-list">
                    <ul>
                        <li v-for="(section,i) in sections" :key="i" class="accordion-item">
                            <a class="item-link item-content">
                                <div class="item-inner">
                                <div class="item-title"><b>{{i}}</b></div>
                                </div>
                            </a>
                            <div class="accordion-item-content">
                                <div class="list links-list">
                                    <ul>
                                        <li class="item-divider"></li>
                                        <li v-for="(asset,j) in section" :key="j"  @click="goToReport(asset.id)" class="block">
                                            <div class="row">
                                                <div class="col-90"><h3 class="asset-name">{{asset.id_cabil}}</h3> </div>
                                                <div class="col-10"><p class="text-align-right"><i class="f7-icons text-align-right">chevron_compact_right</i></p></div>
                                            </div>
                                            <report-progress :report="asset.cabil_app_progress" :count="j"/>
                                        </li>
                                        <li class="item-divider"></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import PouchDB from 'pouchdb'
import reportProgress from '@/components/reports/reportProgress.vue'
import PlaceHolder from '@/components/reports/assetsPlaceholder.vue'
export default {
    name: 'Assets',
    data () {
        return {
            sections: {},
            report: {},
            reportReady: false
        }
    },
    props: {
        id: String
    },
    mounted () {
        this.getReport()
    },
    methods: {
        getReport: function () {
            this.report = this.reports['report_'+this.id]
            let db = new PouchDB('report_'+this.id)
            db.allDocs({startkey: "asset_", endkey: "asset_\ufff0", include_docs: true}).then((assets)=>{
                this.catalogAsset(assets.rows)
            })
        },
        catalogAsset: function (assets) {
            for(let i in assets){
                if(this.sections[assets[i].doc['Tipo']] == undefined){
                    this.sections[assets[i].doc['Tipo']] = []
                }
                this.sections[assets[i].doc['Tipo']].push(assets[i].doc)
            }
            this.reportReady = true
        },
        goBack: function () {
            window.history.back()
        },
        goToReport: function (id){
            window.location.href = '../#/report/'+this.id+'/'+id
        },
        goToMantenimientos: function () {
            window.location.href = '../#/mantenimientos/'+this.id
        }
    },
    computed: {
        reports: function () {
            return this.$store.state.reports.reports
        }
    },
    components: { 
        reportProgress,
        PlaceHolder
    }
}
</script>

<style>
.asset-name{
    margin-bottom:0;
}
.item-divider{
    height:10px !important;
}
</style>
<template>
<div class="report">
    <div class="main">
        <div class="page">
            <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                    <div class="left">
                    <a class="link back" @click="goBack()">
                        <i class="icon icon-back"></i>
                        <span class="if-not-md">Atrás</span>
                    </a>
                    </div>
                    <div class="title"></div>
                </div>
            </div>
            <qrcode-stream @decode="onDecode" style="width:100%;"></qrcode-stream>
        </div>
    </div>
</div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    name: 'QR',
    data () {
        return {
            loading: false
        }
    },
    props:{
        id:String
    },
    components: {
      QrcodeStream
    },
    methods: {
        onDecode (decodedString) {
            this.f7.dialog.preloader('Cargando Reporte');
            setTimeout(()=>{
                this.f7.dialog.close()
                window.location.href = '#/report/'+this.id+'/'+decodedString
            },2000)
            
        },
        goBack: function () {
            window.history.back()
        }
    },
    computed:{
        f7: function () {
            return this.$store.state.framework7.app
        }
    }
}
</script>

<style>

</style>
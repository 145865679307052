<template>
  <div class="inicio">
    <div class="block">
      <h1>Hola {{userName}}!</h1>
    </div>
    <div v-if="reportsExist">
      <div class="block"><h3>Mis reportes guardados</h3></div>
      
      <div class="list links-list">
          <ul>
              <li class="item-divider"></li>
              <li v-for="(report,i) in reports" :key="i">
                <div @click="openQrCamera(report.report.id)" class="fab fab-right-top">
                  <a href="#" >
                    <div class="fab-text"><i class="f7-icons">qrcode</i></div>
                  </a>
                </div>
                <div class="block">
                  <div @click="goToAssets(report.report.id)">
                    Reporte: &nbsp;&nbsp;{{report.report.Nombre}}<br>
                    Cliente: &nbsp;&nbsp;{{report.customer.Nombre_interno}}<br>
                    Activos: &nbsp;&nbsp;{{report.report.cabil_app_assets_num}}<br>
                    <p class="text-align-center"><report-gauge :item="i"/></p>
                  </div>
                  <button class="col button color-blue" @click="openOptions(i,report)">Administrar Reporte</button>
                  </div>
                  <p class="item-divider"></p>
              </li>
          </ul>
      </div>
    </div>
    <div v-if="!reportsExist" class="block">
      <h2 class="text-align-center">No tienes reportes descargados en el dispositivo</h2>
      <p class="text-align-center">Para descargar un reporte y trabajar con él, ve a la sección de empresas eligiendo el menú <i class="f7-icons example-icon">list_bullet</i> o presionando el botón que está abajo</p>
      <button class="col button button-fill color-blue" @click="goToCustomers()">Ir a sección "Empresas"</button>
    </div>
  </div>
</template>

<script>
import ReportGauge from '@/components/reports/reportGauge.vue'
export default {
    name: 'Inicio',
    mounted () {
      
    },
    computed: {
      reports: function () {
        let reports = this.$store.state.reports.reports
        return reports
      },
      reportsExist: function () {
        if(Object.keys(this.reports).length > 0){
          return true
        }
        return false
      },
      f7: function () {
          return this.$store.state.framework7.app
      },
      user: function () {
        return this.$store.state.user
      },
      userName: function () {
        let user = this.user.name
        if(user == undefined){
          return ''
        }
        return this.user.name.split(' ')[0]
      }
    },
    methods: {
      goToAssets: function (id) {
        window.location.href = '#/assets/'+id
      },
      openQrCamera: function (id) {
        window.location.href = '#/qr/'+id
      },
      showDeleteDialog: function (key,report) {
        const vue = this
        this.f7.dialog.confirm('¿Seguro que deseas eliminar este reporte de tu teléfono?<br><br> <b>NOTA:</b> El reporte no se eliminará del servidor y la app hará un reinicio rápido ',report.report.Nombre, function () {
            vue.deleteReport(key)
        }, function () {})
      },
      deleteReport: function (key) {
        this.$store.dispatch('deleteReport',key)
      },
      goToCustomers: function () {
        document.getElementById('main-tab-2').click()
      },
      showUploadDialog: function (i,report) {
        const vue = this
        this.f7.dialog.confirm('¿Seguro que deseas subir el reporte a la nube?<br><br>Este reporte sobreescribirá los datos que alguien más haya subido antes',report.report.Nombre, function () {
            vue.uploadReport(i)
        }, function () {})
      },
      uploadReport: function (i) {
        this.$store.dispatch('updateReport',i)
      },
      openOptions: function (i,report) {
        var vue = this
        const buttons = [
          {
            text: report.report.Nombre,
            label: true
          },
          {
            text: 'Actualizar en la nube',
            color: 'blue',
            strong: true,
            onClick: () => {vue.showUploadDialog(i,report)}
          },
          {
            text: 'Eliminar del teléfono',
            strong: true,
            color: 'red',
            onClick: () => {vue.showDeleteDialog(i,report)}
          },
        ];
        let actionsToPopover = this.f7.actions.create({
          buttons: buttons
        });
        actionsToPopover.open()
      }
    },
    components: {
      ReportGauge
    }
}
</script>

<style lang="css" scoped>
.example-icon{
  font-size: 22px;
}
.fab-right-bottom{
    margin-bottom: 10px;
}
</style>
const data = {
  "data": {
    "Equipo_con_daños": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Equipo_libre_de_obstrucción": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Equipo_inspeccionado": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Acción_Correctiva_Fecha_Compromiso": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion"
  ]
}

module.exports = data
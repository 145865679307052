<template>
<div id="app">
  <div class="app">
    <main-view v-if="layout == 'main'"/>
    <login v-if="layout == 'login'"/>
    <splash v-if="layout == '' || layout == 'splash'"/>
    <loader/>
  </div>
  <div id="not-mobile">
    <h3>Esta es una aplicación hecha para teléfonos o tablets</h3><hr>
    Debes usar algún dispositivo móvil para ver esta sección
  </div>
</div>
  
</template>

<script>
import MainView from "@/components/app/main.vue"
import Login from "@/components/app/login.vue"
import Splash from "@/components/app/splash.vue"
import Loader from "@/components/app/loader.vue"
export default {
  mounted () {
    this.$store.dispatch('startApp');
  },
  computed: {
    layout: function () {
      return this.$store.state.layout
    }
  },
  components: {
    MainView,
    Login,
    Splash,
    Loader
  }
}
</script>

<style>
@import "../node_modules/framework7/framework7-bundle.css";
@import "../node_modules/framework7-icons/css/framework7-icons.css";
#app{
  height:100% !important;
}
.app{
  height:100% !important;
}
#not-mobile{
  display: none;
}
@media (min-width: 992px) {
  .app {
    display: none;
  }
  #not-mobile{
    display: block;
  }
}
</style>

<template>
  <div class="inicio">
    <div class="block">
      <h1><i class="f7-icons">person</i> {{user.name}}</h1>
      <h3><i class="f7-icons">envelope</i>  {{user.email}}</h3>
      <div class="block-title">Opciones</div>
      <div class="list simple-list">
        <ul>
          <li>
            <span>Modo Oscuro</span>
            <label class="toggle toggle-init color-red">
              <input type="checkbox" v-model="darkMode"/>
              <span class="toggle-icon"></span>
            </label>
          </li>
        </ul>
      </div>
      <div class="block-title">Cerrar sesión</div>
      <div class="list simple-list">
        <ul>
          <li class="swipeout">
            <div class="item-content swipeout-content">
              <div class="item-inner">
                <div class="item-title text-align-right">Deslizar a la izquierda para cerrar sesión</div>
              </div>
            </div>
            <div class="swipeout-actions-right">
              <a class="button-fill color-red" @click="confirmLogout()">Confirmar</a>
            </div>
          </li>
        </ul>
      </div>
      <p class="margin-top">&copy; {{year}} AppCabil - ver. {{version}}</p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Profile',
    mounted () {
    },
    computed: {
      f7: function () {
          return this.$store.state.framework7.app
      },
      user: function () {
        return this.$store.state.user
      },
      userName: function () {
        return this.user.name.split(' ')[0]
      },
      version: function () {
        return this.$store.state.version
      },
      year: function () {
        let date = new Date()
        return date.getFullYear()
      },
      darkMode: {
        get () {
          return this.$store.state.darkMode
        },
        set (val) {
          this.$store.commit('setDarkMode',val)
        }
      }
    },
    methods: {
      confirmLogout: function () {
        var vue = this
        var confirmText = '¿Deseas cerrar la sesión en este dispositivo?';
        vue.f7.dialog.confirm(confirmText, function () {
          vue.logout();
        });
      },
      logout: function () {
        this.$store.dispatch('logout')
      }
    },
    components: {
    }
}
</script>

<style lang="css" scoped>
.swipeout-content{
  padding-left:0 !important;
}
</style>
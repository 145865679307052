<template>
  <div class="mantenimientos">
    <div class="main">
        <div class="page">
            <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                    <div class="left">
                    <a class="link back" @click="goBack()">
                        <i class="icon icon-back"></i>
                        <span class="if-not-md">Atrás</span>
                    </a>
                    </div>
                    <div class="title"></div>
                </div>
            </div>
            <place-holder v-if="!loadingReady"/>
            <div v-if="loadingReady" class="page-content">
                <div class="block">
                    <div>
                        <h3>Reportes de Mantenimiento</h3>
                    </div>
                    <hr>
                </div>
                <div class="list links-list list-outline-ios list-strong-ios list-dividers-ios">
                  <ul>
                    <li v-for="(mantenimiento,i) in mantenimientos" :key="i" @click="goToEditMantenimiento(mantenimiento)"><a>{{mantenimiento.doc.Asunto}}</a></li>
                  </ul>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import PouchDB from 'pouchdb'
import PlaceHolder from '@/components/reports/assetsPlaceholder.vue'
export default {
  name:'MantenimientosList',
  props: {
    reportId: String
  },
  data () {
    return {
      loadingReady: false,
      mantenimientos: []
    }
  },
  methods: {
    getMantenimientosFromDb: function () {
      let db = new PouchDB('report_'+this.reportId)
      db.allDocs({startkey: "mantenimiento_", endkey: "mantenimiento_\ufff0", include_docs: true}).then((mantenimientos)=>{
          this.mantenimientos = mantenimientos.rows
          this.loadingReady = true
      })
    },
    goToEditMantenimiento: function (mantenimiento) {
      window.location.href = '../#/mantenimientos/'+this.reportId+'/'+mantenimiento.doc.id
    },
    goBack: function () {
        window.history.back()
    }
  },
  mounted () {
    this.getMantenimientosFromDb()
  },
  components: {
    PlaceHolder
  }

}
</script>

<style>

</style>
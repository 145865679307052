import Framework7 from '../../node_modules/framework7/framework7-bundle'

export default {
  state: () => ({
  }),
  mutations: {
    initF7(state,obj){
      state.app = obj
    }
  },
  actions: {
    initFramework7(context) {
      var app = new Framework7({
        dialog: {
            title: 'App Cabil',
            buttonCancel: 'Cancelar'
        }
    });
      context.commit('initF7',app)
    }
  },
  getters: {}
}
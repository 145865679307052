export default {
  state: () => ({
    selectedId: undefined,
    sources: [],
    localStorageItem: "selectedCamera"
  }),
  mutations: {
    initCamera: function (state) {
        const cameraSource = JSON.parse(localStorage.getItem(state.localStorageItem))
        if(cameraSource != null) {
            state.selectedId = cameraSource.id
        }
        var vue = state
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    var srcObj = {}
                    const mediaDevices = devices.filter(device => device.kind === 'videoinput');
                    mediaDevices.forEach(device => {
                        srcObj = {name: device.label, id: device.deviceId}
                        vue.sources.push(srcObj)
                    });
                })
                .catch(err => {
                    console.error('Error enumerating devices: ', err);
                });
        } else {
            console.error('MediaDevices API not supported');
        }
    },
    setCameraSource: function (state, source) {
        state.selectedId = source.id
        localStorage.setItem(state.localStorageItem,JSON.stringify(source))
    }
  },
  actions: {
  },
  getters: {}
}
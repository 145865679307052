const data = {
  "data": {
    "Fecha_de_realización": {
      "type": "date"
    },
    "Detalle": {
      "type": "textarea"
    },
    "galeria": {
      "type": "textarea"
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Asunto": {
      "type": "input"
    },
    "Firma": {
      "type": "input"
    }
  },
  "assetFields": [
    "id_cabil"
  ]
}

module.exports = data
const data = {
  "data": {
    "Fugas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Alarma_de_Flujo": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A",
        "N/T": "N/T"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Tiempo_en_reportar_al_Tablero": {
      "type": "input"
    },
    "Válvula_Principal": {
      "type": "json",
      "keys": {
        "Abierta-": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Sup_De--": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Lubricada---": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Candado---": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        }
      }
    },
    "Presión_PSI": {
      "type": "json",
      "keys": {
        "Superior-": {
          "type": "input"
        },
        "Inferior--": {
          "type": "input"
        }
      }
    },
    "Función_Trim": {
      "type": "json",
      "keys": {
        "Automático-": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No",
            "N/A": "N/A"
          }
        },
        "Manual--": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No",
            "N/A": "N/A"
          }
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicación"
  ]
}

module.exports = data
const data = {
  "data": {
    "Visibilidad_de_la_Careta": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Funcionamiento_correcto_de_la_matraca": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Rasgaduras_en_guolpa_o_nuquera": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Funcionamiento_correcto_del_barbiquejo": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Funcionamiento_correcto_de_las_suspensiones": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Rasgaduras_y_limpieza": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Rasgaduras_y_limpieza_": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Botones_funcionales": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Broches_funcionales": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Rasgaduras_y_limpieza__": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Botones_funcionales_": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Broches_funcionales_": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Rasgaduras_y_limpieza___": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Sujetadores_funcionalidad": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Limpieza": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Suela_no_liza": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Rasgaduras_y_limpieza____": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Acción_Correctiva_Fecha_Compromiso": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion"
  ]
}

module.exports = data
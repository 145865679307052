<template>
  <div class="customer-placeholder">
    <div class="skeleton-text skeleton-effect-wave">
        <div class="card-header"><h1>Empresas</h1></div>
    </div>
    <div class="list media-list skeleton-text skeleton-effect-wave">
    <ul>
      <li v-for="i in 10" :key="i" class="item-content">
        <div class="item-inner">
          <div class="item-text">
            Placeholder text line 1
            Text line 2
          </div>
        </div>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
export default {
    name: 'CustomerPlaceholder'

}
</script>

<style>

</style>
<template>
    <div class="block">
        <div class="row">
            <div class="col-50">
            <p><a class="link popup-close" href="#"><i class="f7-icons">chevron_down</i>Cerrar</a></p>
            </div>
            <div class="col-50 text-align-right">
            <h3>{{report.Nombre}}</h3>
            </div>
        </div>
        <h2>Mantenimientos</h2>
        <div class="list list-strong-ios list-dividers-ios inset-ios">
            <ul>
                <li class="item-content item-input">
                    <div class="item-inner">
                    <div class="item-title item-label">Nuevo reporte de mantenimiento</div>
                    <div class="item-input-wrap">
                        <input type="text" placeholder="Asunto" v-model="asunto">
                    </div>
                    </div>
                </li>
            </ul>
        </div>
        <button class="col button button-fill color-blue" @click="createMantenimiento()">Crear reporte mantenimiento</button>
        <button class="col button button-fill color-teal margin-top" @click="getMantenimientos">Ver mantenimientos</button>
        <div v-if="watchMantenimientos">
            <p v-if="(mantenimientos.length == 0)" class="text-align-center"> Cargando...</p>
            <div class="list">
                <ul>
                <li v-for="(mantenimiento,i) in mantenimientos" :key="i">
                    <div class="item-content">
                    <div class="item-media"><i class="f7-icons color-red" @click="deleteMantenimiento(mantenimiento,i)">trash</i></div>
                    <div class="item-inner">
                        <div class="item-title">{{mantenimiento.Asunto}}</div>
                    </div>
                    </div>
                </li>
                </ul>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>
export default {
    name: 'AdminForm',
    data () {
        return {
            asunto: '',
            mantenimientos: [],
            watchMantenimientos: false
        }
    },
    props: {
        report: Object
    },
    mounted () {
    },
    methods: {
        createMantenimiento: function () {
            if(this.asunto == ''){
                this.f7.dialog.alert('', 'El reporte de mantenimiento debe tener un asunto...')
                return false
            }
            
            let data = {
                "report": this.report.id,
                "asunto": this.asunto
            }
            this.$store.dispatch('createMantenimiento',data)
            this.asunto = ''
        },
        deleteMantenimiento: function (mantenimiento,i) {
            this.f7.dialog.confirm('¿Seguro que deseas eliminar el mantenimiento del servidor?<br><br> <b>NOTA:</b> Los cambios se verán reflejados en tu teléfono hasta que elimines y vuelvas a descargar el reporte',mantenimiento.Asunto, () => {
                let data = {
                    id: mantenimiento.id,
                    callback: () => {
                        this.mantenimientos.splice(i, 1)
                    }
                }
                this.$store.dispatch('deleteMantenimiento',data)
                
            }, () => {})
        },
        getMantenimientos: function () {
            this.mantenimientos = []
            this.watchMantenimientos = true
            let data = {
                id: this.report.id,
                callback: (mantenimientos) => {
                    this.mantenimientos = mantenimientos
                }
            }
            this.$store.dispatch('getReportMantenimientos',data)
        }
    },
    computed:{
        f7: function () {
            return this.$store.state.framework7.app
        },
    },
}
</script>

<style>

</style>
const eppb = require('./eppb')
const bombaDiesel = require('./bombaDiesel')
const bombaJockey = require('./bombaJockey')
const era = require('./era')
const encapsulado = require('./encapsulado')
const bombaElectrica = require('./bombaElectrica')
const estrobo = require('./estrobo')
const estacionManual = require('./estacionManual')
const riser = require('./riser')
const mantenimiento = require('./mantenimiento')
const hidranteExterior = require('./hidranteExterior')
const extintor = require('./extintor')
const hidrante = require('./hidrante')
const regadera = require('./regadera')
const kitDerrame = require('./kitDerrame')
const sirenaBocina = require('./sirenaBocina')
const valvula = require('./valvula')
const puertaEmergencia = require('./puertaEmergencia')

const forms = {
    'EPPB': eppb,
    'Bomba Diesel': bombaDiesel,
    'Bomba Jockey': bombaJockey,
    'ERA': era,
    'Encapsulado': encapsulado,
    'Bomba Eléctrica': bombaElectrica,
    'Estrobo': estrobo,
    'Estación Manual': estacionManual,
    'Riser': riser,
    'Mantenimiento': mantenimiento,
    'Hidrante exterior': hidranteExterior,
    'Hidrante Monitor': hidranteExterior,
    'Extintor': extintor,
    'Hidrante interior': hidrante,
    'Hidrante': hidrante,
    'Regadera y lavaojos': regadera,
    'Kit de derrame': kitDerrame,
    'Sirenas y bocinas': sirenaBocina,
    'Válvula': valvula,
    'Puerta de emergencia': puertaEmergencia,
}

module.exports = forms
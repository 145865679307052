import Axios from "axios"
import PouchDB from "pouchdb"
import ReportUtils from "../assets/lib/reportUtils"

export default {
  state: () => ({
    loading: false,
    progress: 0,
    reports: {},
    progresses: {},
    reportUrl: process.env.VUE_APP_URL+'/mobilereport'
  }),
  mutations: {
    setReportProgress(state,val){
        state.progress = val
    },
    loadingReport(state,val){
        state.loading = val
    },
    saveNewReport(state,data){
      state.reports['report_'+data.report.id] = data
      localStorage.setItem('reportsList',JSON.stringify(state.reports))
    },
    getSavedReports(state){
      var reports = localStorage.getItem('reportsList')
      if(reports == undefined){
        localStorage.setItem('reportsList','{}')
        state.reports = {}
      }else{
        state.reports = JSON.parse(reports)
      }
    },
    resetReports(state){
      state.reports = {}
      localStorage.setItem('reportsList',JSON.stringify({}))
    }
  },
  actions: {
    getReportsWithAssets(context,id){
        context.commit("loadingText","Descargando Reporte...")
        context.commit("loading",true)
        const config = {
            headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken') }
        };
        Axios.get( 
          context.state.reportUrl+'/'+id,
          config
        ).then(function (response) {
            let report = {
              report: response.data.report,
              customer: response.data.customer
            }
            report.report['cabil_app_assets_num'] = response.data.assets.length
            context.commit('saveNewReport',report)
            context.commit("loadingText","Creando base de datos...")

            let percentage = 0
            let percentageDict = {}
            for(let i in response.data.reports){
              response.data.reports[i]['_id'] = 'report_'+response.data.reports[i].activo_id
              percentage = ReportUtils.getReportProgress(response.data.reports[i]) //Obtener el porcentaje de avance
              response.data.reports[i]['cabil_app_progress'] = percentage
              percentageDict['report_'+response.data.reports[i].activo_id] = percentage //Guardar el porcentaje para el asset
            }

            for(let i in response.data.assets){
              response.data.assets[i]['_id'] = 'asset_'+response.data.assets[i].id
              response.data.assets[i]['cabil_app_progress'] = percentageDict['report_'+response.data.assets[i].id] //Tomar el porcentaje para el asset
            }

            for(let i in response.data.mantenimientos){
              response.data.mantenimientos[i]['_id'] = 'mantenimiento_'+response.data.mantenimientos[i].id
            }

            var db = new PouchDB('report_'+report.report.id);
            db.bulkDocs(response.data.reports).then(()=>{
              db.bulkDocs(response.data.assets).then(()=>{
                db.bulkDocs(response.data.mantenimientos).then(()=>{
                  context.commit("loading",false)
                  document.getElementById("main-tab-1").click()
                  context.rootState.framework7.app.dialog.alert('', '¡Reporte descargado exitosamente!')
                })
              })
            })
            
        }).catch(function (error) {
            context.commit("loading",false)
            if(error.code == 'ERR_NETWORK'){
                context.rootState.framework7.app.dialog.alert('Revisa tu conexión a internet', 'Sin conexión')
            }else{
                context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:RP1', 'Ocurrió un error')
            }
            console.log(error)
        });
    },
    updateReport(context,id){
        context.commit("loadingText","Recopilando información...")
        context.commit("loading",true)
        let data = {
          report: context.state.reports[id].report,
          customer: context.state.reports[id].customer
        }

        const db = new PouchDB(id)
        let arr = []
        let obj = {}
        db.allDocs({startkey: "report_", endkey: "report_\ufff0", include_docs: true}).then((reports)=>{
          arr = []
          obj = {}
          for(let i in reports.rows){
            obj = reports.rows[i].doc
            delete obj._rev
            delete obj._id
            arr.push(obj)
          }
          data['reports'] = arr
        }).then(()=>{
          db.allDocs({startkey: "asset_", endkey: "asset_\ufff0", include_docs: true}).then((asset)=>{
            arr = []
            obj = {}
            for(let i in asset.rows){
              obj = asset.rows[i].doc
              delete obj._rev
              delete obj._id
              delete obj.cabil_app_progress
              arr.push(obj)
            }
            data['assets'] = arr
          }).then(()=>{
            db.allDocs({startkey: "mantenimiento_", endkey: "mantenimiento_\ufff0", include_docs: true}).then((mantenimiento)=>{
              arr = []
              obj = {}
              for(let i in mantenimiento.rows){
                obj = mantenimiento.rows[i].doc
                delete obj._rev
                delete obj._id
                arr.push(obj)
              }
              data['mantenimientos'] = arr
            }).then(()=>{
              context.dispatch('sendReports',data)
            })
          })
        })
    },
    sendReports(context,data){
      context.commit("loadingText","Subiendo Reporte...")
      const config = {
          headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken') }
      };
      Axios.post( 
        context.state.reportUrl,
        data,
        config
      ).then(function () {
          context.commit("loading",false)
          context.rootState.framework7.app.dialog.alert('', '¡Reporte actualizado exitosamente!')
      }).catch(function (error) {
          context.commit("loading",false)
          if(error.code == 'ERR_NETWORK'){
              context.rootState.framework7.app.dialog.alert('Revisa tu conexión a internet', 'Sin conexión')
          }else{
              context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:RP2', 'Ocurrió un error')
          }
          console.log(error)
      });
    },
    deleteReport(context,key){
      context.commit("loadingText","Borrando base de datos...")
      context.commit("loading",true)
      delete context.state.reports[key]
      localStorage.setItem('reportsList',JSON.stringify(context.state.reports))
      let db = new PouchDB(key)
      db.destroy().then(function () {
        context.commit("loading",false)
        window.location.href = '#/refresh/'
      }).catch(function (err) {
        context.commit("loading",false)
        context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:RP3', 'Ocurrió un error')
        console.log(err);
      });
      
    },
    createMantenimiento(context,data){
      context.commit("loadingText","Creando reporte de mantenimiento...")
      context.commit("loading",true)
      const config = {
          headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken') }
      };
      Axios.post( 
        process.env.VUE_APP_URL+'/mobilefunc/createmantenimiento',
        data,
        config
      ).then(function (reponse) {
          context.commit("loading",false)
          console.log(reponse.data)
          context.rootState.framework7.app.dialog.alert('Actualiza tu reporte para ver el nuevo mantenimiento', '¡Mantenimiento creado exitosamente!')
      }).catch(function (error) {
          context.commit("loading",false)
          if(error.code == 'ERR_NETWORK'){
              context.rootState.framework7.app.dialog.alert('Revisa tu conexión a internet', 'Sin conexión')
          }else{
              context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:RP2', 'Ocurrió un error')
          }
          console.log(error)
      });
    },
    deleteMantenimiento(context,data){
      context.commit("loadingText","Eliminando reporte de mantenimiento...")
      context.commit("loading",true)
      const config = {
          headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken') }
      };
      Axios.post( 
        process.env.VUE_APP_URL+'/mobilefunc/deletemantenimiento',
        {id:data.id},
        config
      ).then(function () {
          context.commit("loading",false)
          data.callback()
          context.rootState.framework7.app.dialog.alert('Actualiza tu reporte', '¡Mantenimiento eliminado exitosamente!')
      }).catch(function (error) {
          context.commit("loading",false)
          if(error.code == 'ERR_NETWORK'){
              context.rootState.framework7.app.dialog.alert('Revisa tu conexión a internet', 'Sin conexión')
          }else{
              context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:RP2', 'Ocurrió un error')
          }
          console.log(error)
      });
    },
    getReportMantenimientos(context,data){
      const config = {
          headers: { Authorization: 'Bearer '+localStorage.getItem('sessionToken') }
      };
      Axios.get( 
        process.env.VUE_APP_URL+'/reportes/'+data.id+'/mantenimientos',
        config
      ).then(function (response) {
          data.callback(response.data.data)
      }).catch(function (error) {
          context.commit("loading",false)
          if(error.code == 'ERR_NETWORK'){
              context.rootState.framework7.app.dialog.alert('Revisa tu conexión a internet', 'Sin conexión')
          }else{
              context.rootState.framework7.app.dialog.alert('Contacta a soporte técnico ERR:RP2', 'Ocurrió un error')
          }
          console.log(error)
      });
    }
  },
  getters: {}
}

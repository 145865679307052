<template>
  <div class="main">
    <div class="page">
      <div class="toolbar tabbar toolbar-bottom">
        <div class="toolbar-inner">
          <a id="main-tab-1" href="#tab-1" class="tab-link tab-link-active"><i class="f7-icons">house</i></a>
          <a id="main-tab-2" href="#tab-2" class="tab-link"><i class="f7-icons">cloud_download</i></a>
          <a id="main-tab-3" href="#tab-3" class="tab-link"><i class="f7-icons">plusminus</i></a>
          <a id="main-tab-4" href="#tab-4" class="tab-link"><i class="f7-icons">person_fill</i></a>
        </div>
      </div>
      <div class="tabs-animated-wrap">
        <div class="tabs">
          <div id="tab-1" class="page-content tab tab-active">
            <div class="main-page">
              <home v-if="tab == 1"/>
            </div>
          </div>
          <div id="tab-2" class="page-content tab">
            <div>
              <customers v-if="tab == 2"/>
            </div>
          </div>
          <div id="tab-3" class="page-content tab">
            <div>
              <admin v-if="tab == 3"/>
            </div>
          </div>
          <div id="tab-4" class="page-content tab">
            <div>
              <profile v-if="tab == 4"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const $ = require('jquery')
import Customers from "@/components/customers/customers.vue"
import Home from "@/components/home/home.vue"
import Profile from "@/components/profile/profile.vue"
import Admin from "@/components/admin/admin.vue"

export default {
  name: 'Main',
  data () {
    return {
      tab: 1
    }
  },
  components: {
    Customers,
    Home,
    Profile,
    Admin
  },
  mounted () {
    const vue = this
    $("#tab-1" ).on('tab:show', function() {
        vue.tab1(); 
      });
    $("#tab-2" ).on('tab:show', function() {
        vue.tab2(); 
      });
    $("#tab-3" ).on('tab:show', function() {
        vue.tab3(); 
      });
    $("#tab-4" ).on('tab:show', function() {
        vue.tab4(); 
      });
    this.tab1()
  },
  methods: {
    tab1: function () {
      this.tab = 1
    },
    tab2: function () {
      this.tab = 2
    },
    tab3: function () {
      this.tab = 3
    },
    tab4: function () {
      this.tab = 4
    }
  }
}
</script>

<style scoped>

.fab{
  margin-bottom: 50px;
}
</style>

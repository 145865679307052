const report = {
    reportUrls: [
        '/hidrantes',
        '/extintores',
        '/risers',
        '/hidrantes-exteriores',
        '/eras',
        '/puertas-emergencia',
        '/sirenas-bocinas',
        '/eppbs',
        '/estaciones-manuales',
        '/estrobos',
        '/kit-derrames',
        '/regaderas',
        '/valvulas',
        '/bombas-electricas',
        '/bombas-jockey',
        '/bombas-diesel',
        '/mantenimientos'
    ],
    reportsUrl: '',
    token: '',
    id: 0,
    getReportProgress: function (report) {
        let isJson
        let total = 0
        let completed = 0
        for(var i in report){
            if(i != 'updated_at' 
            && i != 'created_at' 
            && i != 'id' 
            && i != 'activo_id' 
            && i != 'reporte_id'
            && i != '_id'
            && i != '_rev'){
                try {
                    isJson = (typeof report[i] == 'object')?true:false 
                } catch (error) {
                    isJson = false
                }
                if(isJson){ //SI ES JSON
                    if(report[i] != null){
                        for(let j in report[i][0]){
                            total += 1
                            if(report[i][0][j] != null && report[i][0][j] != ''){
                                completed += 1
                            }
                        }
                    } 
                }else{
                    total += 1
                    if(report[i] != null && report[i] != ''){
                        completed += 1
                    }
                } 
            }
        }
        let percentage = 0
        if(total != 0){
            percentage = Math.round((completed*100)/total)
        }
        return percentage
    }
}

module.exports = report
<template>
  <div class="splash">
    <span class="progressbar-infinite"></span>
  </div>
</template>

<script>
export default {
    name: "Splash"
}
</script>

<style scoped>
.splash{
    background-image: url("../../assets/img/splash-cabil.png");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
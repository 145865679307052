const data = {
  "data": {
    "Señalamiento": {
      "type": "radio",
      "options": {
        "Presente": "Presente",
        "Ausente": "Ausente"
      }
    },
    "Mica": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal",
        "N/A": "N/A"
      }
    },
    "Obstruido": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Gabinete": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal",
        "N/A": "N/A"
      }
    },
    "Pitón": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal"
      }
    },
    "Pitón_cantidad": {
      "type": "input"
    },
    "Llave": {
      "type": "radio",
      "options": {
        "Presente": "Presente",
        "Ausente": "Ausente"
      }
    },
    "Fugas_en_tomas_válvulas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Observaciones_de_mangueras": {
      "type": "textarea"
    },
    "Llave_cantidad": {
      "type": "input"
    },
    "Manguera": {
      "type": "json",
      "keys": {
        "Diámetro----": {
          "type": "radio",
          "options": {
            "1Pulgada": "1Pulgada",
            "11/2Pulgada": "11/2Pulgada",
            "2Pulgadas": "2Pulgadas",
            "21/2Pulgadas": "21/2Pulgadas",
            "3Pulgadas": "3Pulgadas"
          }
        },
        "Fecha_de_Fabricación--": {
          "type": "date"
        },
        "Prueba_Hidrostática---": {
          "type": "date"
        },
        "No_de_Manguera-": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Tomas",
    "Ubicación"
  ]
}

module.exports = data
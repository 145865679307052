<template>
  <div class="loader">

  </div>
</template>

<script>
export default {
    name:"loader",
    computed: {
        f7: function () {
        return this.$store.state.framework7.app
        },
        text: function () {
            return this.$store.state.loadingText
        },
        loading: function () {
            return this.$store.state.loading
        }
    },
    watch: {
        loading: function (val) {
            if(val){
                if(this.text == ''){
                    this.f7.dialog.preloader('Cargando...');
                }else{
                    this.f7.dialog.preloader(this.text);
                }
            }else{
                this.f7.dialog.close()
            }
        },
        text: function () {
            if(this.loading){
                this.f7.dialog.close()
                this.f7.dialog.preloader(this.text);
            }
        }
    }
}
</script>

<style>

</style>
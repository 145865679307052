const data = {
  "data": {
    "Accesible": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Detección_de_alarmas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Señalamiento": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Estado_físico": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Válvula_cerrado": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Válvula_abierta_Open": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Vueltas_para_cerrar_completamente": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Vueltas_para_abrir_completamente": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Candado_bloqueado": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Acción_Correctiva_Fecha_Compromiso": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "input"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion"
  ]
}

module.exports = data
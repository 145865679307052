<template>
  <div class="customers">
        <placeholder v-if="loading"/>
        <div v-if="!loading">
            <div v-if="!offline && reports.length == 0">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <div class="title">Administración Reportes</div>
                    </div>
                </div>
                <div class="list links-list">
                    <ul>
                    <li v-for="(customer,i) in customers" :key="i" @click="selectCustomer(customer.id,customer.Nombre_interno,i)">
                        <a href="#">{{customer.Nombre_interno}}</a>
                    </li>
                    </ul>
                </div>
            </div>
            <div v-if="!offline && reports.length > 0">
                <div class="block"><i class="f7-icons" @click="goBack()">arrow_left</i></div>
                
                <div class="block"><h3>Administración de reportes de {{customerName}}</h3></div>
                <div class="list links-list">
                    <ul>
                    <li v-for="(report,i) in reports" :key="i">
                        <a  class="popup-open" href="#" @click="selectReport(report)">{{report.Nombre}}</a>
                    </li>
                    </ul>
                </div>
            </div>
            <div v-if="offline" class="card">
                <div class="card-header">
                    <h2>Modo Offline</h2>
                </div>
                <div class="card-content card-content-padding">
                  <p>No se puede mostrar el panel de administración de reportes sin una conexión a internet.</p>
                </div>
            </div>
        </div>
        <div id="popup-admin" class="popup">
          <admin-form :report="report"/>
        </div>
  </div>
</template>

<script>
import Placeholder from '../customers/customersPlaceholder.vue'
import AdminForm from './adminForm.vue'
export default {
    name: 'Admin',
    data () {
        return {
            section: 'customerlist',
            customerName: '',
            report:{}
        }
    },
    mounted () {
        this.$store.commit('setCustomerReports',[])
        this.$store.dispatch('getAllCustomers')
    },
    components: {
        Placeholder,
        AdminForm
    },
    computed: {
        loading: function () {
            return this.$store.state.customers.loading
        },
        customers: function () {
            return this.$store.state.customers.customers
        },
        offline: function () {
            return this.$store.state.offlineMode
        },
        reports: function () {
            return this.$store.state.customers.reports
        },
        f7: function () {
            return this.$store.state.framework7.app
        },
        reportProgress: function () {
            return this.$store.state.reports.progress
        }
    },
    methods: {
        selectCustomer: function (id,name,index) {
            this.customerName = name
            this.$store.commit('setSelectedCustomer',index)
            this.$store.dispatch('getAllReportsByCustomer',id)
        },
        goBack: function () {
            this.$store.commit('setCustomerReports',[])
            this.$store.dispatch('getAllCustomers')
        },
        selectReport: function (report){
          this.report = report
            this.f7.popup.open('#popup-admin', true)
        }
        
    }
}
</script>

<style>

</style>
const data = {
  "data": {
    "Rejillas_de_ventilación_libres_para_operación": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luz_adecuada": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luz_de_emergencia_adecuada": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Limpieza_del_tablero_de_control_principal": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Limpieza_del_motor_y_bomba": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Control_maestro_con_luces_adecuadas__": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Control_de_bombas_en_automático": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luces_de_emergencia_apagadas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Funcionamiento_adecuado_del_precalentador": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Carga_de_bateria_normal": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luces_piloto_de_baterias_encendidas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luces_piloto_de_automatico_de_la_bomba_enc": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Conexión_elastomérica_en_buenas_condiciones": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_revisaron_las_trampas_del_sistema_de_enfriamiento___": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Válvulas_de_succión_descarga_y_bypass_abiertas_": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Válvula_principal_de_prueba_cerrada": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Tuberías_libres_de_fugas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Condición_de_las_bandas": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal"
      }
    },
    "Se_realizaron_los_7_diferentes_arranques": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_realizó_arranque_en_automático": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_realizó_arranque_manual": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Señales_de_fuera_de_serv_y_alarm_tablero_3030": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Estado_del_alternador": {
      "type": "radio",
      "options": {
        "Bien": "Bien",
        "Mal": "Mal"
      }
    },
    "Reapriete_de_condiciones_mecanicas_y_eléctricas____": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Fecha": {
      "type": "date"
    },
    "Fecha_del_último_cambio_de_aceite": {
      "type": "date"
    },
    "Último_mantenimiento": {
      "type": "date"
    },
    "Comentarios": {
      "type": "textarea"
    },
    "Temperatura_Ambiente": {
      "type": "input"
    },
    "Voltaje_normal_de_la_batería": {
      "type": "input"
    },
    "Rango_normal": {
      "type": "input"
    },
    "Temperatura_del_precalentador": {
      "type": "input"
    },
    "Nivel_de_elctrolitos_de_las_baterías": {
      "type": "input"
    },
    "Presión_de_succión": {
      "type": "input"
    },
    "Presión_de_descarga": {
      "type": "input"
    },
    "Nivel_de_tanque_de_agua_de_suministro": {
      "type": "input"
    },
    "Temperatura_de_agua_en_tanque_de_suministro": {
      "type": "input"
    },
    "Temperatura_y_ajuste_del_empaque_del_sello_del_eje": {
      "type": "input"
    },
    "Nivel_del_tanque_de_diesel": {
      "type": "input"
    },
    "Nivel_del_deposito_de_refrigerante": {
      "type": "input"
    },
    "Nivel_del_aceite_del_motor": {
      "type": "input"
    },
    "Presión_de_aceite_del_motor": {
      "type": "input"
    },
    "Temperatura_del_motor_antes_de_la_prueba": {
      "type": "input"
    },
    "Temperatura_del_motor": {
      "type": "input"
    },
    "Temperatura_de_los_rodamientos": {
      "type": "input"
    },
    "Voltaje_del_banco_de_baterías_1": {
      "type": "input"
    },
    "Voltaje_del_banco_de_baterías_2": {
      "type": "input"
    },
    "Presión_de_arranque_en_automático": {
      "type": "input"
    },
    "Presión_de_trabajo_máx_durante_la_prueba": {
      "type": "input"
    },
    "Velocidad_del_motor": {
      "type": "input"
    },
    "Lectura_inicial_del_horómetro": {
      "type": "input"
    },
    "Lectura_final_del_horómetro": {
      "type": "input"
    },
    "Amperaje_de_bancos_de_baterías_1_y_2": {
      "type": "input"
    }
  },
  "assetFields": [
    "id_cabil"
  ]
}

module.exports = data
const data = {
  "data": {
    "Almohadillas_absorbentes": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Tapetes_absorbentes": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Bolsas_de_absorbente_suelto": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Bolsas_de_recolección_de_residuos": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Googles": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Guantes_para_ácidos": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Masilla_para_reparar": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Bote_de_toallas_húmedas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/A": "N/A"
      }
    },
    "Observaciones": {
      "type": "textarea"
    },
    "Calcetín_absorbente": {
      "type": "json",
      "keys": {
        "Pequeños-": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        },
        "Grandes--": {
          "type": "radio",
          "options": {
            "Sí": "Sí",
            "No": "No"
          }
        }
      }
    },
    "Acción_Correctiva_Fecha_Compromiso": {
      "type": "json",
      "keys": {
        "Fecha_Compromiso--": {
          "type": "date"
        },
        "Acción-": {
          "type": "textarea"
        }
      }
    }
  },
  "assetFields": [
    "id_cabil",
    "Ubicacion"
  ]
}

module.exports = data
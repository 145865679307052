const data = {
  "data": {
    "Rejillas_de_ventilacion_libres_para_operación": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luz_adecuada": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luz_de_emergencia_adecuada": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No",
        "N/T": "N/T"
      }
    },
    "Limpieza_del_tablero_de_control_principal": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Limpieza_del_motor_y_bomba": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Control_maestro_con_luces_adecuadas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Control_de_bombas_en_automático": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luces_de_emergencia_apagadas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Los_baleros__del_motor_se_encuentran_bien_lubricados": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Revisión_y_ajuste_de_los_estoperos_de_la_bomba": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Luces_piloto_de_automatico_de_la_bomba_enc": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Conexión_elastomérica_en_buenas_condiciones": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Conexiones_eléctricas_bien_apretadas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_recibio_señal_de_fuera_de_servicio___": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Válvulas_de_succión_descarga_y_bypass_abiertas_": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Válvula_principal_de_prueba_cerrada": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Tuberías_libres_de_fugas": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_realizó_arranque_en_automático": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_realizó_arranque_manual": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Se_recibieron_sañales_de_alarma_en_el_tablero_principal_3030": {
      "type": "radio",
      "options": {
        "Sí": "Sí",
        "No": "No"
      }
    },
    "Fecha": {
      "type": "date"
    },
    "Comentarios": {
      "type": "textarea"
    },
    "Temperatura_ambiente": {
      "type": "input"
    },
    "Tempertura_de_los_rodamientos": {
      "type": "input"
    },
    "Presión_de_succión": {
      "type": "input"
    },
    "Presión_de_descarga": {
      "type": "input"
    },
    "Nivel_del_tanque_de_succión__": {
      "type": "input"
    },
    "Presión_de_arranque_en_automático": {
      "type": "input"
    },
    "Presión_de_trabajo_máx_durante_la_prueba": {
      "type": "input"
    },
    "Velocidad_del_motor": {
      "type": "input"
    },
    "Tiempo_de_prueba_en_minutos": {
      "type": "input"
    }
  },
  "assetFields": [
    "id_cabil"
  ]
}

module.exports = data
<template>
    <a id="demo-picker-device">
        <i class="icon f7-icons">camera_rotate</i>
    </a>
</template>

<script>
export default {
    name: "CameraSelector",
    data(){
        return {
            picker: null
        }
    },
    mounted () {
        const vue = this
        this.picker = this.f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: this.sourceNames
                }
            ],
            on: {
                change: function (picker, values) {
                    let source = vue.sources.find(obj => obj.name === values[0]);
                    vue.$store.commit("setCameraSource",source)
                }
            }
        })
    },
    methods: {
        showOptions: function () {
            console.log(this.sources)
        }
    },
    computed: {
        sources: function () {
            return this.$store.state.camera.sources
        },
        sourceNames: function () {
            let names = []
            this.sources.forEach(element => {
                names.push(element.name)
            });
            return names
        },
        f7: function () {
            return this.$store.state.framework7.app
        }
    }
}
</script>

<style>

</style>